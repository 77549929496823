<template>
  <div class="flex flex-col h-screen md:mx-auto">
    <TopBar />
    <div class="flex-grow w-full py-5 mt-4 md:max-w-screen-lg md:mx-auto md:px-5">
      <router-view></router-view>
    </div>
    <Footer v-if="!$route.path.includes('admin')" />
  </div>
</template>

<script>
import TopBar from '@ui/TopBar'
import Footer from '@ui/Footer'

export default {
  components: {
    Footer,
    TopBar
  }
}
</script>

<style src="./assets/tailwind.css"></style>
