<template>
  <div class="p-5 bg-gray-200 rounded-lg">
    <h2 class="mb-4 text-lg font-semibold">{{title}}</h2>
    <div>
      <template v-if="products">
        <Draggable v-bind="dragOptions" v-model="mutableProducts" @start="drag = true" @end="updateProductOrder">
          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <div
              v-for="product in mutableProducts"
              :key="product.id"
              class="flex items-center justify-between px-5 py-2 mb-4 bg-white border border-white rounded-lg cursor-pointer product-item"
              :class="drag && 'border-0'"
              @click="$emit('updateProduct', product)">
              <div>{{product.name}}</div>
              <svg viewBox="0 0 24 24" class="w-6 h-6 text-gray-300 fill-current">
                <path class="secondary" d="M7 18.59V9a1 1 0 0 1 2 0v9.59l2.3-2.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 1 1 1.4-1.42L7 18.6z" />
                <path class="primary" d="M17 5.41V15a1 1 0 1 1-2 0V5.41l-2.3 2.3a1 1 0 1 1-1.4-1.42l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.42L17 5.4z" />
              </svg>
            </div>
          </transition-group>
        </Draggable>
      </template>
      <Button
        @click.native="$emit('addProduct')"
        type="secondary">
        Ajouter {{ kind || 'produit'}}
      </Button>
    </div>
  </div>
</template>

<script>
import api from '@store/api'

import Button from '@ui/Button'
import Draggable from 'vuedraggable'

export default {
  components: {
    Button,
    Draggable
  },
  watch: {
    products: {
      handler(products, _) {
        this.mutableProducts = products
      }
    }
  },
  props: {
    products: {
      type: Array
    },
    title: {
      type: String,
      required: true
    },
    startIndex: {
      type: Number,
      required: true
    },
    kind: String
  },
  data() {
    return {
      mutableProducts: this.products,
      drag: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    async updateProductOrder() {
      this.drag = false
      await api.put('/admin/product/index', {
        productIds: this.mutableProducts.map(p => p.id),
        startIndex: this.startIndex
      })
    }
  }
}
</script>

<style lang="css">
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
