import format from 'date-fns/format'
import fr from 'date-fns/locale/fr'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

export default {
  computed: {
    formattedDate() {
      return (dateString, { capitalize } = { capitalize: false }) => {
        const formattedDate = format(new Date(dateString), 'PPPP', { locale: fr })
        return capitalize ? formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1) : formattedDate
      }
    },
    formattedDistanceToNow() {
      return dateString => formatDistanceToNow(new Date(dateString), { addSuffix: true, locale: fr })
    },
    formattedTime() {
      return dateString => format(new Date(dateString), 'HH\'h\'mm', { locale: fr })
    }
  }
}
