<template>
  <div>
    <div class="md:space-y-4">
      <MenuItem
        v-for="menu in menus"
        :key="menu.id"
        :menu="menu"
        @click.native="selectedMenu = menu" />
    </div>
    <transition name="fade">
      <MenuComposer
        v-if="selectedMenu"
        @close="selectedMenu = null"
        :menu="selectedMenu"
        :products="products"
        :isPreviewMode="isPreviewMode" />
    </transition>
  </div>
</template>

<script>
import MenuComposer from '@components/menu/MenuComposer'
import MenuItem from '@components/menu/MenuItem'

export default {
  props: {
    menus: {
      type: Array,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    isPreviewMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MenuComposer,
    MenuItem
  },
  data() {
    return {
      selectedMenu: null
    }
  }
}
</script>
