<template>
  <div>
    <div class="p-5 space-y-4 bg-blue-100 md:rounded-lg">
      <div v-for="item in items" :key="item.question">
        <h1 class="mb-2 text-lg font-semibold leading-tight">{{item.question}}</h1>
        <p class="leading-snug">{{item.response}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          question: 'À quoi ça sert ?',
          response: 'Si un autre client du restaurant, qui était présent le jour de votre venue, est déclaré positif au coronavirus ou cas contact, nous transmettrons aux autorités de santé vos coordonnées. Ce carnet en ligne vous permet de les renseigner rapidement.'
        },
        {
          question: 'Où sont mes données personnelles ?',
          response: 'Elles sont gardées bien au chaud et en sécurité sur un serveur situé en France. Elles sont supprimées au bout de 15 jours si vous n’avez pas souhaité être tenu au courant de nos actualités.'
        }
      ]
    }
  }
}
</script>
