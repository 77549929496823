import axios from 'axios'
import defaultsConfig from 'axios/lib/defaults'
import transformKeys from 'transform-keys'
import * as camelCase from 'lodash.camelcase'
import * as snakeCase from 'lodash.snakecase'

function transformRequest(data) {
  if (data && !data.pickup_config) {
    data = transformKeys(data, snakeCase)
  }
  return data
}

function transformResponse(data) {
  if (data) {
    data = transformKeys(data, camelCase)
  }
  return data
}

export default axios.create({
  baseURL: process.env.VUE_APP_COQ_API_URL,
  headers: {},
  transformRequest: [transformRequest, defaultsConfig.transformRequest[0]],
  transformResponse: [defaultsConfig.transformResponse[0], transformResponse]
})
