<template>
  <div>
    <ProductsEditor :serviceId="$route.params.id" />
  </div>
</template>

<script>
import ProductsEditor from '@components/admin/products/ProductsEditor'

export default {
  components: {
    ProductsEditor
  }
}
</script>
