<template>
  <Modal title="Panier" @close="$emit('close')">
    <div class="p-5 bg-red-100 rounded-lg">
      <CartItem
        v-for="(item, index) in items"
        :key="item.id"
        :item="item"
        :index="index"
        class="mb-4" />
      <hr class="mb-4">
      <div class="flex justify-between mb-1">
        <div class="text-xl font-semibold">
          Total à payer
        </div>
        <div class="text-xl font-semibold text-coq-red">
          {{ cartCents | formattedCents }}
        </div>
      </div>
      <div class="text-sm text-gray-700">
        Paiement au retrait de votre commande.
      </div>
    </div>

    <hr class="my-4">
    <template v-if="!isLoadingPickupTimes">
      <template v-if="hasPickupTimes">
        <div class="text-2xl font-semibold" ref="formContainer">Coordonnées</div>
        <div class="text-sm text-gray-700">
          Ces données sont utilisées exclusivement afin de traiter et d'identifier votre commande.
        </div>
        <div class="mt-4 space-y-4">
          <Input v-model="orderDetail.firstName" label="Prénom" :hasValidationError="$v.orderDetail.firstName.$error" />
          <Input v-model="orderDetail.lastName" label="Nom" :hasValidationError="$v.orderDetail.lastName.$error" />
          <Input v-model="orderDetail.email" type="email" label="E-mail" :hasValidationError="$v.orderDetail.email.$error" />
          <Input v-model="orderDetail.rawTelephone" type="tel" label="Numéro de téléphone mobile" :hasValidationError="$v.orderDetail.rawTelephone.$error" />
        </div>
        <Checkbox identifier="save" class="mt-5" v-model="saveInCookie">
          Conserver mes coordonnées pour ma prochaine commande.
        </Checkbox>
        <div class="mt-4 text-2xl font-semibold">Horaire de retrait</div>
        <div class="text-sm text-gray-700">
          Choisissez l’horaire qui vous convient parmi les créneaux disponibles.
        </div>
        <div class="relative mt-4">
          <select
            v-model="orderDetail.pickupTime"
            class="block w-full px-4 py-3 pr-8 text-lg bg-white border border-gray-300 appearance-none cursor-pointer focus:outline-none focus:bg-white focus:border-coq-red">
            <option v-for="time in pickupTimes" :key="time" :value="time">
              {{service.formattedRelativeDay.charAt(0).toUpperCase() + service.formattedRelativeDay.slice(1)}} à {{time}}
            </option>
          </select>
          <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-600 pointer-events-none">
            <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        <Input
          class="mt-4"
          type="textarea"
          v-model="orderDetail.comment"
          label="Commentaire (facultatif)" />
        <hr class="my-6">
        <Checkbox
          identifier="marketing"
          class="my-5"
          v-model="orderDetail.marketingOptIn">
          Je veux être tenu au courant des actualités du restaurant.
        </Checkbox>
        <div v-if="hasError" class="p-5 mb-6 bg-red-100 border rounded-lg border-coq-red">
          Oups, il y a eu un problème technique.<br>Votre commande n’a pas été validée.<br>
          <br>
          Vérifiez vos coordonneés et l’horaire de retrait et ré-essayez.<br><br>
          Si ça ne marche toujours pas, validez votre commande par téléphone au <br><a class="font-semibold" href="tel:0478935425">04&nbsp;78&nbsp;93&nbsp;54&nbsp;25</a>.
        </div>
        <Button
          :isLoading="isLoading"
          @click.native="submit">
          Valider la commande <span v-if="cartCents > 0">({{ cartCents | formattedCents }})</span>
        </Button>
      </template>
      <OrdersClosed v-else />
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { parsePhoneNumberFromString as parseMobile } from 'libphonenumber-js/mobile'

import Cookies from 'js-cookie'

import Button from '@ui/Button'
import CartItem from '@components/cart/CartItem'
import Checkbox from '@ui/Checkbox'
import Input from '@ui/Input'
import Modal from '@ui/Modal'
import OrdersClosed from '@components/home/OrdersClosed'

const COOKIE_NAME = '__coq_order_detail'

const isTelephoneValid = number => {
  const mobile = parseMobile(number, 'FR')
  return mobile ? mobile.isValid() : false
}

export default {
  components: {
    Button,
    CartItem,
    Checkbox,
    Input,
    Modal,
    OrdersClosed
  },
  data() {
    const state = {
      saveInCookie: false,
      isLoadingPickupTimes: true,
      isLoading: false,
      hasError: false,
      orderDetail: {
        firstName: '',
        lastName: '',
        email: '',
        rawTelephone: '',
        comment: '',
        pickupTime: null,
        marketingOptIn: false
      }
    }

    if (Cookies.get(COOKIE_NAME)) {
      const cookieData = atob(Cookies.get(COOKIE_NAME)).split('|')
      state.orderDetail.firstName = cookieData[0]
      state.orderDetail.lastName = cookieData[1]
      state.orderDetail.email = cookieData[2]
      state.orderDetail.rawTelephone = cookieData[3]
      state.saveInCookie = true
    }

    return state
  },
  computed: {
    ...mapGetters({
      service: 'service/service',
      cart: 'service/cart',
      cartCents: 'service/cartCents',
      pickupTimes: 'service/pickupTimes',
      order: 'order/get'
    }),
    items() {
      return this.cart.orderItems
    },
    menu() {
      return id => this.service.menus.find(m => m.id === id)
    },
    product() {
      return (id, kind) => this.service.products[kind].find(p => p.id === id)
    },
    hasPickupTimes() {
      return this.pickupTimes.length > 0
    },
    orderDetailCookieString() {
      return [
        this.orderDetail.firstName,
        this.orderDetail.lastName,
        this.orderDetail.email,
        this.orderDetail.rawTelephone]
        .join('|')
    }
  },
  methods: {
    async submit() {
      this.hasError = false
      this.$v.$touch()

      if (!this.$v.$error) {
        this.isLoading = true

        try {
          this.orderDetail.telephone = parseMobile(this.orderDetail.rawTelephone, 'FR').number
          await this.$store.dispatch('service/submitOrder', this.orderDetail)

          if (this.saveInCookie) {
            Cookies.set(COOKIE_NAME, btoa(this.orderDetailCookieString), { expires: 31 })
          }
          else {
            Cookies.remove(COOKIE_NAME)
          }

          this.$router.push({ name: 'order', params: { token: this.order.token, new: true } }, () => {
            this.$store.commit('service/reset')
          })
        }
        catch {
          this.hasError = true
          this.isLoading = false
        }
      }
      else {
        this.$refs.formContainer.scrollIntoView()
      }
    }
  },
  async mounted() {
    this.isLoadingPickupTimes = true
    await this.$store.dispatch('service/getPickupTimes')
    this.isLoadingPickupTimes = false

    if (this.pickupTimes.length > 0) {
      this.orderDetail.pickupTime = this.pickupTimes[0]
    }
  },
  validations: {
    orderDetail: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      rawTelephone: { required, isTelephoneValid }
    }
  }
}
</script>
