<template>
  <div class="px-5 md:px-0">
    <template v-if="isOrderLoaded && order">
      <div v-if="$route.params.new" class="p-5 mt-3 mb-6 bg-green-200 rounded-lg">
        <div class="flex items-center mb-3">
          <svg class="h-12 fill-current" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
            <circle class="text-green-600 fill-current" cx="12" cy="12" r="10" />
            <path class="text-green-200 fill-current" d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z" />
          </svg>
          <div class="ml-4">
            <h1 class="text-lg font-semibold text-green-800">Commande validée</h1>
            <h2 class="-mt-1 text-green-800">Merci pour votre soutien !</h2>
          </div>
        </div>
        <p>Nous vous avons envoyé un e-mail et un SMS de confirmation. {{greeting}}</p>
      </div>
      <h1 class="text-2xl font-semibold">Commande #{{order.number}}</h1>
      <p class="text-xl">
        À retirer
        <template v-if="formattedOrderRelativeDay">
          <span class="font-semibold text-coq-red">{{formattedOrderRelativeDay}}</span>,
        </template>
        <span :class="!formattedOrderRelativeDay && 'font-semibold text-coq-red'">
          {{order.formattedServiceDate}}
        </span>
        <br class="md:hidden"> à <span class="font-semibold text-coq-red">{{order.pickupTime}}</span>.
      </p>
      <div class="p-5 mt-4 bg-red-100 rounded-lg">
        <div class="flex justify-between mb-1">
          <div class="text-xl font-semibold">
            Total à payer
          </div>
          <div class="text-xl font-semibold text-coq-red">
            {{ order.cents | formattedCents }}
          </div>
        </div>
        <div class="text-gray-700">
          Paiement au retrait de votre commande.
        </div>
      </div>
      <hr class="my-6">
      <div class="flex flex-col-reverse md:flex-row md:items-start">
        <div class="p-5 bg-blue-100 rounded-lg md:w-1/2">
          <h1 class="mb-6 text-xl font-semibold">Récapitulatif de la commande</h1>
          <div class="space-y-4">
            <OrderItem
              v-for="(item, index) in order.orderItems"
              :key="index"
              :item="item" />
          </div>
          <div class="mt-5 text-gray-700">
            <span class="block font-semibold">Une question, une info ?</span>
            Contactez-nous au <a href="tel:0478935425" class="font-semibold underline">04 78 93 54 25</a>.
          </div>
        </div>
        <div class="p-5 bg-blue-100 rounded-lg md:ml-4 md:w-1/2">
          <h1 class="mb-6 text-xl font-semibold">Lieu de retrait</h1>
          <a
            href="https://g.page/coqenpatelyon?share"
            target="_blank"
            class="block py-2 mb-6 text-lg font-semibold text-center text-white rounded-lg bg-coq-red">
            36 rue Tête d’Or — 69006 Lyon
          </a>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2783.0512582705774!2d4.84985631610222!3d45.7701629791058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4ea8cc89d8875%3A0xd0cb761d742affbd!2sAu%20coq%20en%20p%C3%A2te!5e0!3m2!1sfr!2sfr!4v1588926513244!5m2!1sfr!2sfr"
            width="100%"
            height="300px"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0">
          </iframe>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isToday, isTomorrow, format } from 'date-fns'
import { fr } from 'date-fns/locale'

import OrderItem from '@components/order/OrderItem'

export default {
  components: {
    OrderItem
  },
  computed: {
    ...mapGetters({
      order: 'order/get',
      isOrderLoaded: 'order/isLoaded'
    }),
    notFound() {
      return !this.$route.params.token || (this.isOrderLoaded && !this.order)
    },
    formattedOrderRelativeDay() {
      const serviceDate = new Date(this.order.serviceDate)
      if (isToday(serviceDate)) {
        return 'aujourd’hui'
      }
      else if (isTomorrow(serviceDate)) {
        return 'demain'
      }

      return null
    },
    greeting() {
      const serviceDate = new Date(this.order.serviceDate)
      if (isToday(serviceDate)) {
        return 'À tout à l’heure !'
      }
      else if (isTomorrow(serviceDate)) {
        return 'À demain !'
      }

      return `À ${format(serviceDate, 'EEEE', { locale: fr })} !`
    }
  },
  async mounted() {
    if (this.$route.params.token) {
      await this.$store.dispatch('order/get', this.$route.params.token)
      if (this.isOrderLoaded && !this.order) {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>
