<template>
  <div>
    <h1 class="mb-5 text-xl font-semibold" v-if="title">{{ title }}</h1>
    <div class="space-y-4">
      <div
        v-for="product in products"
        :key="product.id"
        class="flex items-center">
        <input
          :id="product.id"
          type="radio"
          :name="product.kind"
          :value="product"
          @change="selectProduct(product)"
          v-model="selectedProduct"
          class="hidden" />
        <label :for="product.id" class="flex items-start cursor-pointer">
          <div class="relative z-0 flex items-center justify-center w-6 h-6 mr-2 border border-gray-400 rounded-full">
            <div class="w-3 h-3 rounded-full checked"></div>
          </div>
          <div class="flex-1">
            <div class="flex items-baseline font-semibold title">
              <div>{{ product.name }}</div>
              <div
                class="flex-shrink-0 inline-block px-2 ml-2 text-sm font-extrabold bg-red-100 rounded-sm text-coq-red"
                v-if="product.extraCents > 0">
                + {{product.extraCents | formattedCents}}
              </div>
              <div
                class="flex-shrink-0 inline-block px-2 ml-2 text-sm font-extrabold text-gray-600 bg-gray-200 rounded-sm"
                v-else-if="product.extraCents < 0">
                – {{Math.abs(product.extraCents) | formattedCents}}
              </div>
            </div>
            <div class="text-sm italic">{{ product.description }}</div>
            <div
              class="mt-2 space-y-2"
              v-if="showOptions(product)">
              <div
                v-for="key in Object.keys(options)"
                :key="key"
                @change="emit">
                <div class="relative">
                  <select
                    class="block w-full px-4 py-2 pr-8 bg-white border border-gray-300 appearance-none focus:outline-none focus:bg-white focus:border-coq-red"
                    v-model="options[key]">
                    <option
                      v-for="option in product.options[key]"
                      :value="option"
                      :key="option">
                      {{key.charAt(0).toUpperCase() + key.slice(1)}} {{option}}
                    </option>
                  </select>
                  <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    products: {
      type: Array,
      required: true
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      selectedProduct: null,
      options: {}
    }
  },
  computed: {
    showOptions() {
      return product => (this.selectedProduct && this.selectedProduct.id === product.id && product.options)
    }
  },
  methods: {
    selectProduct(product) {
      this.selectedProduct = product

      if (product.options) {
        this.options = {}
        Object.keys(product.options).forEach(key => {
          this.options[key] = product.options[key][0]
        })
      }
      else {
        this.options = null
      }

      this.emit()
    },
    emit() {
      this.$emit('change', {
        id: this.selectedProduct.id,
        kind: this.selectedProduct.kind,
        extraCents: this.selectedProduct.extraCents,
        options: this.options
      })
    }
  },
  mounted() {
    this.selectProduct(this.products[0])
  }
}
</script>

<style lang="css">
input[type="radio"]:checked + label .checked {
  @apply bg-coq-red;
}

input[type="radio"]:checked + label .title {
  @apply text-coq-red;
}
</style>
