<template>
  <div>
    <h1 class="text-2xl font-semibold mb-5">Produits permanents</h1>
    <ProductsEditor :reverseKinds="true" :startIndex="200" />
  </div>
</template>

<script>
import ProductsEditor from '@components/admin/products/ProductsEditor'

export default {
  components: {
    ProductsEditor
  }
}
</script>
