<template>
  <div>
    <label
      :for="id"
      :class="hasValidationError ? 'text-red-600 font-semibold' : 'text-gray-600'">
      {{label}}
    </label>
    <textarea
      v-if="type === 'textarea'"
      class="block w-full px-3 py-2 mt-1 text-black rounded-sm outline-none appearance-none"
      :class="hasValidationError ? 'border-2 border-red-600 focus:border-red-600' : 'border border-gray-300 focus:border-coq-red'"
      :id="id"
      :name="id"
      :value="value"
      @input="$emit('change', $event.target.value)"
      cols="30"
      rows="3">
    </textarea>
    <input
      v-else
      class="block w-full px-3 py-2 mt-1 text-gray-900 rounded-sm outline-none appearance-none"
      :class="hasValidationError ? 'border-2 border-red-600 focus:border-red-600' : 'border border-gray-300 focus:border-coq-red'"
      :type="type"
      :id="id"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('change', $event.target.value)">
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    id: String,
    label: String,
    value: [String, Number],
    placeholder: String,
    hasValidationError: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }
  }
}
</script>
