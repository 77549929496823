<template>
  <div>
    <div v-if="serviceLoaded">
      <div class="relative" v-if="isServiceAvailable">
        <DailySummary class="mb-4" :service="service" />
        <h1 class="px-5 mb-4 text-2xl font-bold md:px-0 text-coq-red">Composez votre menu</h1>
        <div
          v-if="isOrderPossible"
          class="md:flex md:items-start">
          <MenuList
            class="md:w-1/2 lg:w-3/5"
            :class="cartCents > 0 && 'mb-4 md:mb-0'"
            :menus="service.menus"
            :products="service.products" />
          <div class="hidden p-5 bg-red-100 md:block md:w-1/2 lg:w-2/5 md:ml-4 md:rounded-lg">
            <CartSummary @showOrder="showOrderModal = true" />
          </div>
          <div v-if="cartCents > 0" class="fixed bottom-0 z-0 w-full p-5 md:hidden ">
            <Button @click.native="showOrderModal = true" class="shadow-2xl">
              <span>Panier ({{cartCents | formattedCents}})</span>
            </button>
          </div>
          <transition name="fade">
            <OrderForm @close="showOrderModal = false" v-if="showOrderModal" />
          </transition>
        </div>
        <OrdersClosed v-else />
      </div>
      <ServiceClosed v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isFuture } from 'date-fns'

import Button from '@ui/Button'
import DailySummary from '@components/home/DailySummary'
import CartSummary from '@components/cart/CartSummary'
import MenuList from '@components/menu/MenuList'
import OrderForm from '@components/cart/OrderForm'
import OrdersClosed from '@components/home/OrdersClosed'
import ServiceClosed from '@components/home/ServiceClosed'

export default {
  components: {
    Button,
    DailySummary,
    CartSummary,
    MenuList,
    OrderForm,
    OrdersClosed,
    ServiceClosed
  },
  data() {
    return {
      showOrderModal: false
    }
  },
  computed: {
    ...mapGetters({
      service: 'service/service',
      serviceLoaded: 'service/serviceLoaded',
      cartCents: 'service/cartCents'
    }),
    isServiceAvailable() {
      return this.service.date
    },
    isOrderPossible() {
      return isFuture(new Date(this.service.orderEndDate)) &&
        this.service.pickupTimes.length > 0
    }
  },
  methods: {
    pageFocused() {
      this.$store.dispatch('service/get')
    }
  },
  created() {
    window.addEventListener('focus', this.pageFocused)
  },
  mounted() {
    this.pageFocused()
  },
  destroyed() {
    window.removeEventListener('focus', this.pageFocused)
  }
}
</script>
