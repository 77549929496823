<template>
  <div class="fixed inset-0 z-50 flex justify-center w-full h-full md:bg-black md:bg-opacity-75">
    <button tabindex="-1" class="absolute top-0 left-0 w-full h-full bg-transparent cursor-default" @click="$emit('close')"></button>
    <div class="relative w-full h-full overflow-y-auto bg-white modal-content md:max-w-screen-sm md:h-auto md:my-20 md:rounded-lg md:shadow-xl">
      <div class="flex items-center p-5 bg-white shadow">
        <button class="text-3xl font-bold" @click="$emit('close')">
          <svg class="w-6 h-6 text-black fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </button>
        <div class="ml-5 text-2xl font-semibold">{{ title }}</div>
      </div>
      <div class="p-5">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  },
  mounted() {
    document.querySelector('body').classList.add('overflow-hidden')
  },
  destroyed() {
    setTimeout(() => {
      document.querySelector('body').classList.remove('overflow-hidden')
    }, 250)
  }

}
</script>
