import api from '../api'

const initialState = {
  service: {},
  serviceLoaded: false,
  cart: { orderItems: [] },
  pickupTimes: []
}

const state = { ...initialState }

const getters = {
  serviceLoaded({ serviceLoaded }) {
    return serviceLoaded
  },
  service({ service }) {
    return service
  },
  allProducts({ service }) {
    const products = Object.keys(service.products).map(kind => service.products[kind])
    return [].concat(...products)
  },
  cart({ cart }) {
    return cart
  },
  cartCents({ service, cart }) {
    return cart.orderItems.reduce((sum, item) => {
      const menu = service.menus.find(m => m.id === item.menuId)
      const extraCents = item.products.reduce((extraCents, product) => extraCents + product.extraCents, 0)
      return sum + (menu.cents + extraCents) * item.quantity
    }, 0)
  },
  pickupTimes({ pickupTimes }) {
    return pickupTimes
  }
}

const actions = {
  async get(context) {
    try {
      const { data } = await api.get('/service')
      context.commit('setService', data)
    }
    catch {
      context.commit('reset')
    }
  },
  async getPickupTimes(context) {
    try {
      const { data } = await api.get('/pickup_times')
      return context.commit('setPickupTimes', data)
    }
    catch {
      return context.commit('setPickupTimes', [])
    }
  },
  async submitOrder(context, order) {
    order.serviceId = context.state.service.id
    const { data } = await api.post('/order', {
      order: { ...order, serviceId: context.state.service.id },
      orderItems: context.state.cart.orderItems
    })

    context.commit('order/setOrder', data, { root: true })
    context.commit('reset')
  }
}

const mutations = {
  reset(state) {
    Object.assign(state, initialState)
  },
  setService(state, service) {
    if (state.service.id !== service.id) {
      state.cart = { ...initialState.cart }
    }

    state.service = service
    state.serviceLoaded = true
  },
  addOrderItem({ cart }, orderItem) {
    cart.orderItems = [...cart.orderItems, orderItem]
  },
  removeOrderItem({ cart }, orderItemIndex) {
    cart.orderItems.splice(orderItemIndex, 1)
  },
  changeQuantity({ cart }, { orderItemIndex, quantity }) {
    cart.orderItems[orderItemIndex].quantity = quantity
  },
  setPickupTimes(state, pickupTimes) {
    state.pickupTimes = pickupTimes
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
