<template>
  <div>
    <div v-if="!order.smsSentAt" class="flex items-center justify-between px-5 py-2 bg-orange-200 rounded-t-lg">
      <div class="text-sm font-semibold">
        ⚠️ Le SMS n’a pas pu être envoyé
      </div>
      <button
        @click="$emit('sendSms')"
        class="px-2 py-1 text-xs font-bold tracking-tight uppercase bg-orange-300 rounded hover:bg-orange-400 hover:rounded-lg">
        Renvoyer
      </button>
    </div>
    <div
      class="p-5 bg-white cursor-pointer hover:bg-gray-100"
      :class="order.smsSentAt ? 'rounded-lg' : 'rounded-b-lg'"
      @click="showOrderItems = !showOrderItems">
      <div class="flex items-center justify-between space-x-4">
        <div class="text-lg">
          <span class="font-semibold text-gray-600">#{{order.number}}</span>
          <span class="ml-2">{{order.firstName}} {{order.lastName}}</span>
        </div>
        <div class="flex items-center">
          <div class="px-5 py-1 font-semibold bg-red-100 rounded-full text-coq-red">
            {{order.cents | formattedCents}}
          </div>
          <div class="ml-5">
            <a @click="$emit('sendInternalEmail')">
              <svg viewBox="0 0 24 24" class="w-6 h-6 text-gray-500 fill-current hover:text-coq-red">
                <path class="primary" d="M22 8.62V18a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8.62l9.55 4.77a1 1 0 0 0 .9 0L22 8.62z" />
                <path class="secondary" d="M12 11.38l-10-5V6c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v.38l-10 5z" />
              </svg>
            </a>
          </div>
          <div class="ml-5">
            <a :href="$router.resolve({name: 'order', params: {token: order.token}}).href" target="_blank">
              <svg viewBox="0 0 24 24" class="w-6 h-6 text-gray-500 fill-current hover:text-coq-red">
                <path d="M12 8a1 1 0 0 1-1 1H5v10h10v-6a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h6a1 1 0 0 1 1 1z" />
                <path d="M19 6.41L8.7 16.71a1 1 0 1 1-1.4-1.42L17.58 5H14a1 1 0 0 1 0-2h6a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V6.41z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <template v-if="showOrderItems">
        <div class="flex items-start justify-between">
          <div class="">{{order.telephone}} — {{order.email}}</div>
          <div class="mt-2 text-sm text-gray-600">Commandée le {{formattedDate(order.createdAt)}} à {{formattedTime(order.createdAt)}}</div>
        </div>
        <div
          class="pl-2 mt-2 text-sm italic border-l-4"
          v-if="order.comment">
          {{order.comment}}
        </div>
        <hr class="my-4">
        <div class="flex">
          <div class="w-3/5 space-y-2">
            <div class="font-semibold">Commande</div>
            <div
              v-for="(orderItem, index) in order.orderItems"
              :key="index">
              <span>{{orderItem.quantity}} x {{orderItem.menu.name}}</span><br>
              <span class="text-gray-600"> ({{orderItemProducts(orderItem)}})</span>
            </div>
          </div>
          <div class="w-px mx-6 border-l"></div>
          <div class="w-2/5 space-y-1">
            <div class="font-semibold">Produits</div>
            <div
              v-for="(productItem, index) in order.productItems"
              :key="index">
              <span>{{productItem.quantity}} x {{productItemName(productItem)}}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showOrderItems: false
    }
  },
  computed: {
    orderItemProducts() {
      return orderItem => {
        return orderItem.products.map(p => {
          if (p.options) {
            const key = Object.keys(p.options)[0]
            return `${p.name.toLowerCase()} ${p.options[key]}`
          }

          return `${p.name.toLowerCase()}`
        }).join(', ')
      }
    },
    productItemName() {
      return p => {
        if (p.options) {
          const key = Object.keys(p.options)[0]
          return `${p.product.name} ${p.options[key]}`
        }

        return `${p.product.name}`
      }
    }
  }
}
</script>
