<template>
  <div>
    <div class="flex justify-between mb-2">
      <div class="flex items-start text-lg font-semibold">
        <div class="relative flex-shrink-0 w-12">
          <select
            @change="changeQuantity"
            v-model="quantity"
            class="block w-full pl-2 font-semibold bg-white border border-gray-300 appearance-none cursor-pointer focus:outline-none focus:bg-white focus:border-coq-red">
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
          <div class="absolute inset-y-0 right-0 flex items-center px-1 text-gray-700 pointer-events-none">
            <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
        <div class="ml-3">{{menu.name}}</div>
      </div>
      <div class="text-lg font-semibold">{{cartItemCents | formattedCents}}</div>
    </div>
    <p v-for="product in products" :key="product.id">
      <span>{{ product.name }}</span>
      <span class="text-sm">{{options(product.id)}}</span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      quantity: this.item.quantity,
      kinds: ['special', 'meal', 'desert', 'alcohol', 'soft']
    }
  },
  watch: {
    item: {
      deep: true,
      handler(newItem, _) {
        this.quantity = newItem.quantity
      }
    }
  },
  computed: {
    ...mapGetters({
      service: 'service/service',
      allProducts: 'service/allProducts'
    }),
    cartItemCents() {
      const extraCents = this.products.reduce((extraCents, product) => extraCents + product.extraCents, 0)
      return (this.menu.cents + extraCents) * this.quantity
    },
    menu() {
      return this.service.menus.find(m => m.id === this.item.menuId)
    },
    productIds() {
      return this.item.products.map(p => p.id)
    },
    products() {
      return this.allProducts.filter(p => this.productIds.includes(p.id))
        .sort((a, b) => this.productIndex(a.kind) - this.productIndex(b.kind))
    },
    options() {
      return id => {
        const options = this.item.products.find(p => p.id === id).options
        return options &&
          ` (${Object.keys(options).map(key => `${options[key]}`).join(', ')})`
      }
    }
  },
  methods: {
    productIndex(kind) {
      return this.kinds.findIndex(k => k === kind)
    },
    changeQuantity() {
      if (this.quantity < 1) {
        this.$store.commit('service/removeOrderItem', this.index)
      }
      else {
        this.$store.commit('service/changeQuantity', {
          orderItemIndex: this.index,
          quantity: this.quantity
        })
      }
    }
  }
}
</script>
