<template>
  <Modal @close="$emit('close')" title="Nouveau service">
    <label
      for="date"
      class="block mb-1 text-gray-600">
      Date du service
    </label>
    <Datetime
      :min-datetime="new Date().toISOString()"
      input-id="date"
      title="Date du service"
      :phrases="{ok: 'OK', cancel: 'Annuler'}"
      @input="dateChanged"
      class="block w-full px-3 py-2 text-gray-900 border border-gray-300 rounded-sm outline-none appearance-none focus:border-coq-red"
      v-model="service.date" />

    <div v-if="service.date" class="mt-6 space-y-6">
      <label
        class="block mb-1 text-gray-600">
        Date de début des commandes
      </label>
      <Datetime
        :phrases="{ok: 'OK', cancel: 'Annuler'}"
        type="datetime"
        class="block w-full px-3 py-2 text-gray-900 border border-gray-300 rounded-sm outline-none appearance-none focus:border-coq-red"
        v-model="service.orderStartDate" />
      <label
        class="block mb-1 text-gray-600">
        Date de fin des commandes
      </label>
      <Datetime
        :phrases="{ok: 'OK', cancel: 'Annuler'}"
        type="datetime"
        class="block w-full px-3 py-2 text-gray-900 border border-gray-300 rounded-sm outline-none appearance-none focus:border-coq-red"
        v-model="service.orderEndDate" />
      <label
        class="block mb-1 text-gray-600">
        Date de fin du service
      </label>
      <Datetime
        :phrases="{ok: 'OK', cancel: 'Annuler'}"
        type="datetime"
        class="block w-full px-3 py-2 text-gray-900 border border-gray-300 rounded-sm outline-none appearance-none focus:border-coq-red"
        v-model="service.endDate" />
      <Button @click.native="submit">Ajouter le service</Button>
    </div>
  </Modal>
</template>

<script>
import api from '@store/api'

import Button from '@ui/Button'
import Modal from '@ui/Modal'

import { Settings } from 'luxon'
import { setHours, setMinutes, subDays, formatISO } from 'date-fns'
import { Datetime } from 'vue-datetime'

Settings.defaultLocale = 'fr'

export default {
  components: {
    Button,
    Datetime,
    Modal
  },
  data() {
    return {
      service: {
        date: null,
        endDate: null,
        orderStartDate: null,
        orderEndDate: null
      },
      hasError: false
    }
  },
  methods: {
    async submit() {
      try {
        this.hasError = false
        const { data } = await api.post('/admin/service', { service: this.service })
        this.$router.push({ name: 'service', params: { id: data.id } })
      }
      catch {
        this.hasError = true
      }
    },
    dateChanged() {
      if (this.service.date) {
        const previousDate = subDays(new Date(this.service.date), 1)
        this.service.orderStartDate = formatISO(setHours(new Date(previousDate), 16))
        this.service.orderEndDate = formatISO(setHours(setMinutes(new Date(this.service.date), 30), 11))
        this.service.endDate = formatISO(setHours(new Date(this.service.date), 14))
      }
    }
  }
}
</script>

<style src="@assets/vue-datetime.min.css"></style>

<style lang="css">
.vdatetime {
  margin: 0 !important;
}
</style>
