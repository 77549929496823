<template>
  <div v-if="service">
    <div>
      <table class="w-full border-collapse">
        <tbody>
          <tr>
            <td class="w-1/2 py-5">
              <div class="text-2xl font-semibold">
                Service du {{service.formattedDate}}
              </div>
              <div class="-mt-1 text-sm text-gray-600">
                Fin du service à {{formattedTime(service.endDate)}}
              </div>
              <button
                @click="sendOrderEndEmails"
                class="px-2 py-1 mt-2 text-xs font-bold tracking-tight uppercase border rounded hover:bg-gray-200">
                Envoyer les récapitulatifs
              </button>
            </td>
            <td class="w-1/2 text-sm text-right">
              <div>
                {{formattedDate(service.orderStartDate)}} à {{formattedTime(service.orderStartDate)}}&nbsp;&nbsp;&nbsp;
              </div>
              <div>
                → {{formattedDate(service.orderEndDate)}} à {{formattedTime(service.orderEndDate)}}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex mt-4 mb-6 space-x-2 border-b-2">
      <RouterLink
        class="block px-5 py-2 text-lg font-semibold bg-gray-100 rounded-t-lg hover:bg-gray-200"
        :to="{name: 'service'}">
        Produits du jour
      </RouterLink>
      <RouterLink
        class="block px-5 py-2 text-lg font-semibold bg-gray-100 rounded-t-lg hover:bg-gray-200"
        :to="{name: 'serviceOrders'}">
        <div class="flex items-center">
          <div>Commandes</div>
          <div class="w-auto px-2 ml-2 text-xs font-bold text-white rounded-full bg-coq-red">{{service.ordersCount}}</div>
        </div>

      </RouterLink>
      <RouterLink
        class="block px-5 py-2 text-lg font-semibold bg-gray-100 rounded-t-lg hover:bg-gray-200"
        :to="{name: 'servicePreview'}">
        Aperçu
      </RouterLink>
    </div>
    <router-view />
  </div>
</template>

<script>
import api from '@store/api'

export default {
  data() {
    return {
      service: null,
      orderInfos: null
    }
  },
  methods: {
    async getData() {
      const res = await api.get(`/admin/service/${this.$route.params.id}`)
      this.service = res.data
    },
    async sendOrderEndEmails() {
      await api.put(`/admin/service/${this.$route.params.id}/send_order_end_emails`)
      window.alert('✅ E-mails renvoyés ! 🤞')
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.getData()
    }
    else {
      this.$router.push({ name: 'services' })
    }
  }
}
</script>

<style lang="css" scoped>
.router-link-active {
  @apply bg-gray-300;
}
</style>
