import api from '../api'

const state = {

}

const getters = {

}

const actions = {
  async submit(_, customerVisits) {
    const { data } = await api.post('/customer_visits/', { customerVisits })
    return data
  }
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
