import api from '../api'
import router from '../../router'

const SESSION_TOKEN = 'COQ-SESSION-TOKEN'
api.defaults.headers.common.Authorization = localStorage.getItem(SESSION_TOKEN)

const state = {
  sessionToken: localStorage.getItem(SESSION_TOKEN),
  user: null
}

const getters = {
  hasSession(state) {
    return state.sessionToken !== null
  },
  user(state) {
    return state.user
  }
}

const actions = {
  async signin(context, credentials) {
    const response = await api.post('/admin/account/signin', credentials)
    context.commit('setSession', response.data.sessionId)
    context.commit('setUser', response.data.user)

    router.push({ name: 'services' })
  },
  async signout(context) {
    await api.post('/admin/account/signout')
    context.commit('destroySession')
  },
  async get(context) {
    try {
      const response = await api.get('/admin/account')
      return context.commit('setUser', response.data)
    }
    catch (error) {
      return context.dispatch('handleAPIError', error)
    }
  }
}

const mutations = {
  setSession(state, token) {
    state.sessionToken = token
    localStorage.setItem(SESSION_TOKEN, state.sessionToken)
    api.defaults.headers.common.Authorization = state.sessionToken
  },
  setUser(state, user) {
    state.user = user
  },
  destroySession(state) {
    localStorage.clear()
    state.sessionToken = null
    state.user = null
    api.defaults.headers.common.Authorization = null
    router.push({ name: 'home' })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
