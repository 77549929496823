import api from '../api'

const state = {
  isLoaded: false,
  order: null
}

const getters = {
  get({ order }) {
    return order
  },
  isLoaded({ isLoaded }) {
    return isLoaded
  }
}

const actions = {
  async get(context, id) {
    const order = context.state.order

    if (order && order.id === id) return
    try {
      const { data } = await api.get(`/order/${id}`)
      context.commit('setOrder', data)
    }
    catch {
      context.commit('setOrder', null)
    }
  }
}

const mutations = {
  setOrder(state, order) {
    state.order = order
    state.isLoaded = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
