<template>
  <div>
    <div class="flex items-start justify-between mb-2">
      <div class="flex text-lg font-semibold">
        {{item.quantity}} x {{item.menu.name}}
      </div>
      <div class="ml-2 text-lg font-semibold">{{item.cents | formattedCents}}</div>
    </div>
    <p v-for="product in products" :key="product.id">
      <span>{{ product.name }}</span>
      <span class="text-sm">{{options(product.id)}}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      kinds: ['special', 'meal', 'desert', 'alcohol', 'soft']
    }
  },
  computed: {
    products() {
      return this.item.products
    },
    options() {
      return id => {
        const options = this.item.products.find(p => p.id === id).options
        return options &&
          ` (${Object.keys(options).map(key => `${options[key]}`).join(', ')})`
      }
    }
  },
  methods: {
    productIndex(kind) {
      return this.kinds.findIndex(k => k === kind)
    }
  }
}
</script>
