<template>
  <div>
    <h1 class="mb-5 text-2xl font-semibold">Carnet de rappel</h1>
    <template v-for="day in Object.keys(customerVisits)">
      <div :key="day" class="p-5 my-4 bg-gray-200 rounded-lg">
        <div class="flex items-center pb-5">
          <h2 class="text-lg font-semibold">
            {{day}}
          </h2>
          <div class="w-auto px-2 ml-2 text-xs font-bold text-white rounded-full bg-coq-red">{{customerVisits[day].length}}</div>
        </div>
        <div class="p-5 space-y-4 bg-white rounded-lg">
          <div v-for="(visit, index) in customerVisits[day]" :key="visit.id">
            <div class="flex justify-between">
              <div>
                <div>{{visit.name}}</div>
                <div>{{visit.telephone}}</div>
              </div>
              <div class="text-right">
                <div>{{visit.formattedCreatedTime}}</div>
                <div v-if="visit.marketingOptIn">✅</div>
                <div v-else>🔇</div>
              </div>
            </div>
            <hr class="my-4" v-if="index !== customerVisits[day].length - 1">
          </div>

        </div>
      </div>
    </template>
  </div>
</template>

<script>
import api from '@store/api'

export default {
  data() {
    return {
      customerVisits: []
    }
  },
  async mounted() {
    const { data } = await api.get('/admin/customer_visits')
    this.customerVisits = data
  }
}
</script>
