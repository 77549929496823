<template>
  <div>
    <h2 class="mb-4 text-xl font-bold text-center md:text-left">{{ title }}</h2>
    <ul class="space-y-2">
      <li
        v-for="product in products"
        :key="product.id">
        <div class="font-semibold">
          • {{ product.name }}
        </div>
        <div class="pl-4 text-sm italic">
          {{ product.description }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  }
}
</script>
