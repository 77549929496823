<template>
  <div v-if="!serverResponse">
    <Help />
    <div class="w-full px-5 pt-5 mx-auto md:pl-0 md:w-2/3">
      <div class="mb-4 text-2xl font-semibold">Coordonnées</div>
      <div class="space-y-2">
        <Input v-model="customer.name" label="Prénom et nom" :hasValidationError="$v.customer.name.$error" />
        <Input v-model="customer.rawTelephone" type="tel" label="Numéro de téléphone" :hasValidationError="$v.customer.rawTelephone.$error" />
        <Input v-model="customer.email" type="email" label="E-mail (facultatif)" :hasValidationError="$v.customer.email.$error" />
      </div>
      <Checkbox identifier="save" class="mt-4" v-model="saveInCookie">Conserver ces informations pour ma prochaine visite</Checkbox>
      <hr class="my-4">
      <div v-for="(additionalCustomer, index) in additionalCustomers" :key="additionalCustomer.id" class="mb-5">
        <hr v-if="index > 0" class="my-4">
        <div class="flex items-center justify-between mb-4">
          <div class="text-2xl font-semibold">{{index + 2}}<sup>ème</sup> coordonnées</div>
          <button
            @click="removeCustomer(index)"
            class="block px-2 py-1 text-xs font-bold tracking-wide text-gray-500 uppercase border border-gray-400 rounded-md ">
            Retirer
          </button>
        </div>
        <div class="space-y-2">
          <Input v-model="additionalCustomer.name" label="Prénom et nom" :hasValidationError="$v.additionalCustomers.$each.$iter[index].name.$error" />
          <Input v-model="additionalCustomer.rawTelephone" type="tel" label="Numéro de téléphone" :hasValidationError="$v.additionalCustomers.$each.$iter[index].rawTelephone.$error" />
        </div>
      </div>
      <Button class="" type="tertiary" @click.native="addCustomer">+ Ajouter une autre personne</Button>
      <hr class="my-4">
      <Checkbox identifier="marketing" class="mt-5" v-model="customer.marketingOptIn">Je veux être tenu au courant des actualités du restaurant</Checkbox>
      <Button :isLoading="isLoading" class="mt-5" type="primary" @click.native="submit">Envoyer les coordonnées</Button>
      <div v-if="hasError" class="p-5 mt-5 bg-red-200 rounded-lg">Oups. Il y a eu un problème technique lors de l’envoi des coordonnées. Réessayez ou rapprocher vous de notre équipe.</div>
    </div>
  </div>
  <div v-else>
    <div class="p-5 mb-6 bg-green-200 md:rounded-lg">
      <div class="flex items-center mb-3">
        <svg class="h-12 fill-current" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
          <circle class="text-green-600 fill-current" cx="12" cy="12" r="10" />
          <path class="text-green-200 fill-current" d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z" />
        </svg>
        <div class="ml-4">
          <h1 class="text-xl font-semibold text-green-800">C’est tout bon&nbsp;!</h1>
          <h2 v-if="serverResponse.length > 1" class="-mt-1 text-lg text-green-800">Vous avez enregistré {{serverResponse.length}} personnes.</h2>
          <h2 v-else class="-mt-1 text-lg text-green-800">Vous vous êtes enregistré.</h2>
        </div>
      </div>
      <p>Montrez nous cet écran à la commande pour s’assurer que tout est en ordre.</p>
      <p class="mt-4 font-semibold ">Bon appétit&nbsp;!</p>
    </div>
    <div class="p-5 mb-6 bg-red-200">
      <h1 class="text-xl font-semibold text-coq-red">Le Coq, c’est aussi à emporter&nbsp;!</h1>
      <p class="mb-4">Commandez votre prochain déjeuner sur notre site. Récupérez votre commande à l’heure de votre choix.</p>
      <Button @click.native="$router.push('/')">Découvrir le drive</Button>
    </div>
  </div>
</template>

<script>
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import Help from '@components/customerVisit/Help'
import Input from '@ui/Input'

import Cookies from 'js-cookie'
import { required, email } from 'vuelidate/lib/validators'
import { parsePhoneNumberFromString as parsePhone } from 'libphonenumber-js/min'

const isTelephoneValid = number => {
  const mobile = parsePhone(number, 'FR')
  return mobile ? mobile.isValid() : false
}

export default {
  components: {
    Button,
    Checkbox,
    Help,
    Input
  },
  data() {
    const customerModel = {
      name: '',
      rawTelephone: '',
      marketingOptIn: false,
      email: ''
    }

    const customer = { ...customerModel }

    if (Cookies.get('visit')) {
      const cookieData = atob(Cookies.get('visit')).split('|')
      customer.name = cookieData[0]
      customer.rawTelephone = cookieData[1]
    }

    return {
      isLoading: false,
      serverResponse: null,
      hasError: false,
      additionalCustomers: [],
      saveInCookie: customer.name !== '',
      customer,
      customerModel
    }
  },
  methods: {
    addCustomer() {
      this.additionalCustomers.push({
        ...this.customerModel,
        id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
      })
    },
    removeCustomer(index) {
      this.additionalCustomers.splice(index, 1)
    },
    async submit() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.isLoading = true
        this.customer.telephone = parsePhone(this.customer.rawTelephone, 'FR').number

        this.additionalCustomers.forEach((_, index, additionalCustomers) => {
          additionalCustomers[index].telephone = parsePhone(additionalCustomers[index].rawTelephone, 'FR').number
        })

        try {
          this.isLoading = false
          this.hasError = false
          this.serverResponse = await this.$store.dispatch('customerVisits/submit', [this.customer, ...this.additionalCustomers])

          if (this.saveInCookie) {
            Cookies.set('visit', btoa(`${this.customer.name}|${this.customer.rawTelephone}`), { expires: 31 })
          }
          else {
            Cookies.remove('visit')
          }
        }
        catch (error) {
          this.hasError = true
        }
      }
    }
  },
  validations() {
    const defaultValidations = {
      name: { required },
      rawTelephone: { required, isTelephoneValid },
      email: { email }
    }

    const validations = {
      customer: defaultValidations,
      additionalCustomers: {
        $each: defaultValidations
      }
    }

    return validations
  }
}
</script>
