<template>
  <button
    v-if="type === 'secondary'"
    :disabled="isLoading"
    class="block w-full py-2 font-semibold text-center bg-white border rounded-lg text-coq-red border-coq-red focus:outline-none focus:shadow-md active:shadow-none"
    :class="!isLoading ? 'hover:bg-red-200' : null">
    <div class="relative">
      <div :class="{'opacity-0': isLoading}">
        <slot />
      </div>
      <div v-if="isLoading" class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full">
        <div class="w-2 h-2 mr-3 bg-red-200 rounded-full loading delay-1"></div>
        <div class="w-2 h-2 mr-3 bg-red-200 rounded-full loading delay-2"></div>
        <div class="w-2 h-2 bg-red-200 rounded-full loading"></div>
      </div>
    </div>
  </button>
  <button
    v-else-if="type === 'tertiary'"
    :disabled="isLoading"
    class="block w-full py-2 font-semibold text-center text-gray-500 bg-white border border-gray-400 rounded-lg focus:outline-none focus:shadow-md active:shadow-none"
    :class="!isLoading ? 'hover:bg-gray-200' : null">
    <div class="relative">
      <div :class="{'opacity-0': isLoading}">
        <slot />
      </div>
      <div v-if="isLoading" class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full">
        <div class="w-2 h-2 mr-3 bg-red-200 rounded-full loading delay-1"></div>
        <div class="w-2 h-2 mr-3 bg-red-200 rounded-full loading delay-2"></div>
        <div class="w-2 h-2 bg-red-200 rounded-full loading"></div>
      </div>
    </div>
  </button>
  <button
    v-else
    :disabled="isLoading"
    class="block w-full py-3 text-lg font-bold text-center text-white rounded-lg bg-coq-red focus:outline-none focus:shadow-md active:shadow-none"
    :class="!isLoading ? 'hover:bg-red-600' : null">
    <div class="relative">
      <div :class="{'opacity-0': isLoading}">
        <slot />
      </div>
      <div v-if="isLoading" class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full">
        <div class="w-2 h-2 mr-3 bg-red-200 rounded-full loading delay-1"></div>
        <div class="w-2 h-2 mr-3 bg-red-200 rounded-full loading delay-2"></div>
        <div class="w-2 h-2 bg-red-200 rounded-full loading"></div>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    type: String
  }
}
</script>

<style lang="css">
.loading {
  animation-name: loading-animation;
  animation-duration: 0.9s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.delay-1 {
  animation-delay: 0.6s;
}

.delay-2 {
  animation-delay: 0.3s;
}

@keyframes loading-animation {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
</style>
