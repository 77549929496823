<template>
  <div>
    <input
      :id="identifier"
      type="checkbox"
      @change="$emit('change', $event.target.checked)"
      :checked="value"
      class="hidden" />
    <label :for="identifier" class="flex items-start cursor-pointer">
      <div class="relative z-0 flex items-center justify-center w-6 h-6 mr-3 border border-gray-500 rounded">
        <div class="w-3 h-3 rounded-sm checked"></div>
      </div>
      <div class="flex-1">
        <div class="">
          <slot />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    identifier: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="css">
input[type="checkbox"]:checked + label .checked {
  @apply bg-coq-red;
}

input[type="checkbox"]:checked + label .title {
  @apply text-coq-red;
}
</style>
