<template>
  <div class="max-w-sm mx-auto space-y-6">
    <Input label="E-mail" type="email" v-model="email" />
    <Input label="Mot de passe" type="password" v-model="password" />
    <Button @click.native="submit">Connexion</Button>
    <div v-if="hasError" class="p-5 bg-red-100 text-coq-red rounded-lg">
      Mauvaise combination e-mail / mot de passe
    </div>
  </div>
</template>

<script>
import Button from '@ui/Button'
import Input from '@ui/Input'

export default {
  components: {
    Button,
    Input
  },
  data() {
    return {
      email: '',
      password: '',
      hasError: false
    }
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('account/signin', {
          email: this.email, password: this.password
        })
      }
      catch {
        this.hasError = true
      }
    }
  }
}
</script>
