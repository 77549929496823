<template>
  <div v-if="service" class="relative">
    <DailySummary class="mb-4" :service="service" />
    <h1 class="px-5 md:px-0 text-2xl text-coq-red font-bold mb-4">Composez votre menu</h1>
    <MenuList
      :menus="service.menus"
      :products="service.products"
      :isPreviewMode="true" />
  </div>
</template>

<script>
import api from '@store/api'

import DailySummary from '@components/home/DailySummary'
import MenuList from '@components/menu/MenuList'

export default {
  components: {
    DailySummary,
    MenuList
  },
  data() {
    return {
      service: null
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      const { data } = await api.get(`/admin/service/${this.$route.params.id}/preview`)
      this.service = data
    }
  }
}
</script>
