import Vue from 'vue'
import Vuex from 'vuex'

import account from '@modules/account'
import customerVisits from '@modules/customerVisits'
import order from '@modules/order'
import service from '@modules/service'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    customerVisits,
    order,
    service
  },
  strict: process.env.NODE_ENV !== 'production'
})
