<template>
  <Modal :title="menu.name" @close="$emit('close')">
    <div v-if="menu.hasSpecial">
      <ProductPicker @change="productChange" :products="products.special" />
      <hr class="my-6" />
    </div>
    <div v-if="menu.hasMeal">
      <ProductPicker @change="productChange" title="Plat" :products="products.meal" />
      <hr class="my-6" />
    </div>
    <div v-if="menu.hasDesert">
      <ProductPicker @change="productChange" title="Dessert" :products="products.desert" />
      <hr class="my-6" />
    </div>
    <div v-if="menu.hasAlcohol">
      <ProductPicker @change="productChange" title="Boisson alcoolisée" :products="products.alcohol" />
      <hr class="my-6" />
    </div>
    <div v-if="menu.hasSoft">
      <ProductPicker @change="productChange" title="Boisson non alcoolisée" :products="products.soft" />
      <hr class="my-6" />
    </div>
    <div v-if="!isPreviewMode">
      <div class="flex items-center">
        <h1 class="block mr-4 text-xl font-semibold">Quantité</h1>
        <div class="relative w-12">
          <select v-model="quantity" class="block w-full pl-2 font-semibold bg-white border border-gray-300 appearance-none cursor-pointer focus:outline-none focus:bg-white focus:border-coq-red">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
          <div class="absolute inset-y-0 right-0 flex items-center px-1 text-gray-700 pointer-events-none">
            <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <hr class="my-6" />
    </div>
    <Button v-if="!isPreviewMode" @click.native="addToCart">
      Ajouter au panier ({{ orderItemCents | formattedCents }})
    </Button>
  </Modal>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

import Button from '@ui/Button'
import Modal from '@ui/Modal'
import ProductPicker from '@components/menu/ProductPicker'

export default {
  props: {
    menu: Object,
    products: {
      type: Object,
      required: true
    },
    isPreviewMode: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Button,
    Modal,
    ProductPicker
  },
  data() {
    return {
      items: [],
      quantity: 1
    }
  },
  computed: {
    orderItemCents() {
      const extraCents = this.items.reduce((extraCents, item) => extraCents + item.extraCents, 0)
      return (this.menu.cents + extraCents) * this.quantity
    }
  },
  methods: {
    productChange(item) {
      this.items = [...this.items.filter(i => i.kind !== item.kind), item]
    },
    addToCart() {
      this.$store.commit('service/addOrderItem', {
        id: uuidv4(),
        menuId: this.menu.id,
        quantity: this.quantity,
        products: this.items
      })

      this.$emit('close')
    }
  }
}
</script>
