<template>
  <div v-if="services">
    <h1 class="mb-5 text-2xl font-semibold">Gestion des services</h1>
    <Button
      @click.native="serviceFormDisplayed = true"
      class="w-64">
      Nouveau service
    </Button>
    <table class="w-full mt-5 border-collapse">
      <tbody>
        <tr
          class="border-b-2 cursor-pointer hover:bg-red-100"
          @click="$router.push({name: 'service', params: {id: service.id}})"
          v-for="service in services"
          :key="service.id">
          <td class="p-5 ">
            <div class="text-2xl">
              {{service.formattedDate}}
            </div>
            <div class="-mt-1 text-sm text-gray-600">
              Fin du service à {{formattedTime(service.endDate)}}
            </div>
          </td>
          <td class="text-sm">
            <div>
              {{formattedDate(service.orderStartDate)}} à {{formattedTime(service.orderStartDate)}}
            </div>
            <div>
              → {{formattedDate(service.orderEndDate)}} à {{formattedTime(service.orderEndDate)}}
            </div>
          </td>
          <td>
            <div class="p-2 font-semibold text-center bg-red-100 rounded-full text-coq-red">
              {{ service.ordersCount }} commandes
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <Button
      v-if="currentOffset !== maxOffset"
      @click.native="getServices"
      class="mt-5"
      type="secondary">
      Afficher plus
    </Button>
    <transition name="fade">
      <ServiceForm
        v-if="serviceFormDisplayed"
        @close="serviceFormDisplayed = false" />
    </transition>
  </div>
</template>

<script>
import ServiceForm from '@components/admin/services/ServiceForm'
import Button from '@ui/Button'
import api from '@store/api'

export default {
  components: {
    Button,
    ServiceForm
  },
  data() {
    return {
      services: [],
      currentOffset: 0,
      maxOffset: 0,
      serviceFormDisplayed: false
    }
  },
  methods: {
    async getServices() {
      const { data } = await api.get(`/admin/services?offset=${this.currentOffset}`)

      this.services = this.services.concat(data.services)
      this.currentOffset = data.currentOffset + 1
      this.maxOffset = data.maxOffset
    }
  },
  mounted() {
    this.getServices()
  }
}
</script>
