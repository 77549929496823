<template>
  <Modal
    :title="product ? 'Modifier un produit' : 'Ajouter un produit'"
    @close="$emit('close')">
    <div v-if="productForm">
      <div class="mb-5 space-y-4">
        <Input label="Nom" v-model="productForm.name" :hasValidationError="$v.productForm.name.$error" />
        <Input type="textarea" label="Description (sans retours à la ligne)" v-model="productForm.description" />
        <div>
          <label for="kind" class="text-gray-600">Type de produit</label>
          <div class="relative">
            <select
              id="kind"
              class="block w-full px-4 py-2 pr-8 mt-2 bg-white border border-gray-300 appearance-none focus:outline-none focus:bg-white focus:border-coq-red"
              :class="$v.productForm.kind.$error && 'border-coq-red'"
              v-model="productForm.kind">
              <option
                v-for="kind in availableKinds"
                :key="kind.value"
                :value="kind.value">
                {{kind.singular}}
              </option>
            </select>
            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
              <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <Input type="number" label="Supplément en euros" v-model="extraCents" />
        <div>
          <label for="kind" class="text-gray-600">Option</label>
          <template v-if="productForm.optionsForm.length === 0">
            <button
              class="w-8 h-8 ml-2 text-xl font-semibold bg-red-100 border rounded-full border-coq-red text-coq-red"
              @click="productForm.optionsForm.push({type: '', values: []})">
              +
            </button>
          </template>
          <template v-else>
            <div
              v-for="(optionForm, typeIndex) in productForm.optionsForm"
              :key="typeIndex">
              <div class="flex items-center mb-4 items">
                <Input class="w-11/12" placeholder="Nom de l'option" v-model="productForm.optionsForm[typeIndex].type" :hasValidationError="$v.productForm.optionsForm.$error" />
                <button
                  class="w-8 h-8 ml-2 text-xl font-semibold bg-red-100 border rounded-full border-coq-red text-coq-red"
                  @click="productForm.optionsForm = []">
                  -
                </button>
              </div>
              <div class="pl-4 pr-12 mt-2 space-y-4 border-l-8">
                <div class="flex items-center items"
                  v-for="(value, valueIndex) in productForm.optionsForm[typeIndex].values"
                  :key="valueIndex">
                  <Input class="w-11/12" v-model="productForm.optionsForm[typeIndex].values[valueIndex]" :hasValidationError="$v.productForm.optionsForm.$error" />
                  <button
                    class="w-8 h-8 ml-2 text-xl font-semibold bg-red-100 border rounded-full border-coq-red text-coq-red"
                    @click="productForm.optionsForm[typeIndex].values.splice(valueIndex, 1)">
                    -
                  </button>
                </div>
                <button
                  class="h-8 px-2 text-sm font-semibold bg-red-100 border rounded-full border-coq-red text-coq-red"
                  @click="productForm.optionsForm[typeIndex].values.push('')">
                  Ajouter un élément
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
      <Button
        :isLoading="isLoading"
        @click.native="submit">
        {{product ? 'Modifier' : 'Ajouter'}}
      </Button>
      <div v-if="product">
        <hr class="my-6" />
        <Button
          type="secondary"
          :isLoading="isLoading"
          @click.native="remove">
          Supprimer
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import kindsModel from '@utils/kinds'

import api from '@store/api'

import Button from '@ui/Button'
import Input from '@ui/Input'
import Modal from '@ui/Modal'

const isOptionsValid = options =>
  options.every(option =>
    option.type.length > 0 && option.values.every(value => value.length > 0))

export default {
  components: {
    Button,
    Input,
    Modal
  },
  props: {
    product: Object,
    serviceId: String,
    initialKind: String,
    kinds: Array
  },
  data() {
    return {
      extraCents: 0,
      productForm: null,
      hasError: false,
      isLoading: false,
      availableKinds: null
    }
  },
  computed: {
    options() {
      if (this.productForm.optionsForm.length === 0) return null

      const options = {}
      this.productForm.optionsForm.forEach(productForm => {
        options[productForm.type] = productForm.values
      })

      return options
    }
  },
  methods: {
    async submit() {
      this.$v.$touch()

      if (!this.$v.$error) {
        try {
          this.isLoading = true
          this.hasError = false
          this.productForm.options = this.options
          this.productForm.extraCents = this.extraCents * 100

          if (this.product) {
            await api.put(`/admin/product/${this.product.id}`, { product: this.productForm })
          }
          else {
            await api.post('/admin/product', { product: this.productForm })
          }

          this.$emit('closeAndReload')
        }
        catch {
          this.isLoading = false
          this.hasError = true
        }
      }
    },
    async remove() {
      if (confirm('Êtes-vous sur de vouloir supprimer ce produit ? Il ne sera plus disponible.')) {
        await api.delete(`/admin/product/${this.product.id}`)
        this.$emit('closeAndReload')
      }
    }
  },
  mounted() {
    this.productForm = this.product ? { ...this.product } : { kind: this.initialKind }
    this.extraCents = this.productForm.extraCents ? this.productForm.extraCents / 100 : 0

    if (!this.product && this.serviceId) {
      this.productForm.serviceId = this.serviceId
    }

    let optionsForm = []
    if (this.productForm.options) {
      optionsForm = Object.keys(this.productForm.options).map(k => ({ type: k, values: [...this.productForm.options[k]] }))
    }

    this.$set(this.productForm, 'optionsForm', optionsForm)

    this.availableKinds = this.kinds
      ? this.kinds.map(k => ({ ...kindsModel[k], value: k }))
      : Object.keys(kindsModel).map(k => ({ ...kindsModel[k], value: k }))
  },
  validations: {
    productForm: {
      name: { required },
      kind: { required },
      optionsForm: { isOptionsValid }
    }
  }
}
</script>

<style src="@assets/vue-datetime.min.css"></style>

<style lang="css">
.vdatetime {
  margin: 0 !important;
}
</style>
