<template>
  <div>
    <h1 class="mb-4 text-xl font-semibold text-coq-red">Panier</h1>
    <div>
      <CartItem
        v-for="(item, index) in items"
        :key="item.id"
        :index="index"
        :item="item"
        class="mb-6" />
    </div>
    <template v-if="items.length === 0">
      <div>
        Votre panier est actuellement vide.
      </div>
    </template>
    <Button v-else @click.native="$emit('showOrder')">
      Commander <span v-if="cartCents > 0">({{cartCents |formattedCents}})</span>
    </Button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Button from '@ui/Button'
import CartItem from '@components/cart/CartItem'

export default {
  components: {
    Button,
    CartItem
  },
  computed: {
    ...mapGetters({
      service: 'service/service',
      cart: 'service/cart',
      cartCents: 'service/cartCents'
    }),
    items() {
      return this.cart.orderItems
    },
    menu() {
      return id => this.service.menus.find(m => m.id === id)
    },
    product() {
      return (id, kind) => this.service.products[kind].find(p => p.id === id)
    }
  }
}
</script>
