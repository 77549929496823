<template>
  <div>
    <h1 class="px-5 text-2xl font-bold md:px-0 text-coq-red">{{title}}</h1>
    <h2 class="px-5 mb-5 -mt-1 md:px-0">{{service.formattedDate}}</h2>
    <div class="bg-white md:flex md:justify-between">
      <div class="p-5 bg-blue-100 md:w-1/2 md:rounded-lg md:p-5">
        <DailySummaryItem title="Les plats" v-if="meals" :products="meals" />
      </div>
      <div class="p-5 mt-6 bg-blue-100 md:w-1/2 md:my-0 md:ml-8 md:rounded-lg md:p-5">
        <DailySummaryItem title="Les desserts" v-if="deserts" :products="deserts" />
      </div>
    </div>
    <div class="w-full mt-6 overflow-hidden bg-blue-100 md:rounded-lg">
      <h2 class="p-5 py-2 text-xl font-bold text-white bg-blue-800 md:text-left">
        <div>Le pack apéro découverte 🍻</div>
      </h2>
      <div class="p-5">
        <p>
          En partenariat avec <b>HUBSTER</b>, jeunes et dynamiques créateurs lyonnais de bières collaboratives, nous vous proposons de belles rencontres !
        </p>
        <div class="justify-around mt-4 md:flex">
          <ul class="space-y-2">
            <li>
              <div class="font-semibold">• Up Town Lager 5°C</div>
              <div class="pl-4 text-sm italic md:pl-0">Blonde légère, désaltérante avec un truc en plus.</div>
            </li>
            <li>
              <div class="font-semibold">• Hop Side IPA 6,3°C</div>
              <div class="pl-4 text-sm italic md:pl-0">Une petite bombe d'arômes à l'amertume modérée.</div>
            </li>
          </ul>
          <ul class="mt-2 space-y-2 md:mt-0 md:ml-4">
            <li>
              <div class="font-semibold">• Vice Hood Witbier 5°C</div>
              <div class="pl-4 text-sm italic md:pl-0">Blanche savoureuse brassée avec des fleurs de jasmin.</div>
            </li>
            <li>
              <div class="font-semibold">• Juicy Ride New England IPA 5,8°C</div>
              <div class="pl-4 text-sm italic md:pl-0">Moins amère que l'IPA, plus onctueuse, follement tropicale.</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DailySummaryItem from './DailySummaryItem'

export default {
  components: {
    DailySummaryItem
  },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  computed: {
    title() {
      const prefix = this.service.formattedRelativeDay
      return `${prefix.charAt(0).toUpperCase() + prefix.slice(1)} au Coq`
    },
    meals() {
      return this.service.products.meal
    },
    deserts() {
      return this.service.products.desert
    }
  }
}
</script>
