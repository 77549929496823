import Vue from 'vue'
import VueRouter from 'vue-router'

import store from './store'

import Home from '@views/Home'
import Order from '@views/Order'
import CustomerVisit from '@views/CustomerVisit'

import Signin from '@views/admin/Signin'

import Carnet from '@views/admin/Carnet'
import Services from '@views/admin/Services'
import Service from '@views/admin/service/Index'
import ServiceProducts from '@views/admin/service/Products'
import ServicePreview from '@views/admin/service/Preview'
import ServiceOrders from '@views/admin/service/Orders'

import Products from '@views/admin/Products'

Vue.use(VueRouter)

// Can be loaded unconditionnally
const PUBLIC_ROUTES = [
  'home',
  'order',
  'maintenance',
  'customer_visits'
]

// Can be loaded only when signed out
const UNAUTHENTICATED_ROUTES = [
  'signin'
]

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      name: 'home',
      path: '/',
      component: Home
    },
    {
      name: 'order',
      path: '/order/:token',
      component: Order
    },
    {
      name: 'customer_visits',
      path: '/carnet',
      component: CustomerVisit,
      meta: {
        subTitle: 'Carnet de rappel en ligne'
      }
    },
    {
      name: 'signin',
      path: '/admin/signin',
      component: Signin,
      meta: {
        subTitle: 'Administration'
      }
    },
    {
      name: 'products',
      path: '/admin/products',
      component: Products,
      meta: {
        subTitle: 'Administration'
      }
    },
    {
      name: 'carnet',
      path: '/admin/carnet',
      component: Carnet,
      meta: {
        subTitle: 'Administration'
      }
    },
    {
      name: 'services',
      path: '/admin/services',
      component: Services,
      meta: {
        subTitle: 'Administration'
      }
    },
    {
      path: '/admin/services/:id',
      component: Service,
      meta: {
        subTitle: 'Administration'
      },
      children: [
        {
          name: 'service',
          path: 'products',
          component: ServiceProducts,
          meta: {
            subTitle: 'Administration'
          }
        },
        {
          name: 'servicePreview',
          path: 'preview',
          component: ServicePreview,
          meta: {
            subTitle: 'Administration'
          }
        },
        {
          name: 'serviceOrders',
          path: 'orders',
          component: ServiceOrders,
          meta: {
            subTitle: 'Administration'
          }
        }
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }

    if (to.meta.preserveScroll) {
      return false
    }

    if (savedPosition) {
      return savedPosition
    }

    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, _from, next) => {
  const isPublicRoute = PUBLIC_ROUTES.some(route => route === to.name)
  const isUnauthenticatedRoute = UNAUTHENTICATED_ROUTES.some(route => route === to.name)

  if (isPublicRoute) {
    next()
  }
  else {
    if (store.getters['account/hasSession']) {
      isUnauthenticatedRoute ? next('/') : next()
    }
    else {
      isUnauthenticatedRoute ? next() : next('/')
    }
  }
})

export default router
