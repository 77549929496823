<template>
  <div v-if="products">
    <div class="grid items-stretch grid-cols-2 gap-4 row-gap-8 mb-6">
      <div v-for="kind in sortedKinds" :key="kind">
        <ProductList
          :title="kindsModel[kind].plural"
          :kind="kindsModel[kind].singular.toLowerCase()"
          @addProduct="addProduct(kind)"
          @updateProduct="updateProduct"
          :products="products[kind]"
          :startIndex="startIndex" />
      </div>
    </div>
    <transition name="fade">
      <ProductForm
        v-if="productFormDisplayed"
        @close="productFormDisplayed = false"
        @closeAndReload="closeAndReload"
        :product="selectedProduct"
        :serviceId="serviceId"
        :initialKind="selectedKind" />
    </transition>
  </div>
</template>

<script>
import api from '@store/api'
import kindsModel from '@utils/kinds'

import ProductForm from '@components/admin/products/ProductForm'
import ProductList from '@components/admin/products/ProductList'

export default {
  props: {
    serviceId: String,
    reverseKinds: Boolean,
    startIndex: {
      type: Number,
      default: 0
    }
  },
  components: {
    ProductForm,
    ProductList
  },
  data() {
    return {
      products: null,
      productFormDisplayed: false,
      selectedProduct: null,
      selectedKind: null
    }
  },
  computed: {
    kindsModel() {
      return kindsModel
    },
    sortedKinds() {
      const kinds = Object.keys(kindsModel)
      return this.reverseKinds ? kinds.reverse() : kinds
    }
  },
  methods: {
    addProduct(kind) {
      this.selectedProduct = null
      this.productFormDisplayed = true
      this.selectedKind = kind
    },
    updateProduct(product) {
      this.selectedProduct = product
      this.productFormDisplayed = true
      this.selectedKind = null
    },
    async closeAndReload() {
      await this.getData()
      this.productFormDisplayed = false
    },
    async getData() {
      const route = this.serviceId ? `/admin/service/${this.serviceId}/products` : '/admin/products'
      const { data } = await api.get(route)
      this.products = data
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
