<template>
  <button class="block w-full p-5 text-left transition-shadow duration-150 border-b md:border md:rounded-lg md:hover:shadow-lg focus:outline-none focus:shadow-outline">
    <h1 class="text-lg font-semibold">{{ menu.name }}</h1>
    <div>{{ menu.description }}</div>
    <div class="font-semibold text-coq-red">{{menu.cents | formattedCents}}</div>
  </button>
</template>

<script>
export default {
  props: {
    menu: {
      type: Object,
      required: true
    }
  }
}
</script>
