<template>
  <div v-if="orderInfos">
    <PickupTimeStatus
      :pickupConfig="orderInfos.pickupConfig"
      :pickupTimes="orderInfos.pickupTimes"
      :orders="orderInfos.orders" />
    <div class="mt-5">
      <Button type="secondary" v-if="!pickupFormDisplayed" @click.native="pickupFormDisplayed = true">Changer les créneaux</Button>
      <div v-if="pickupFormDisplayed" class="p-5 bg-gray-200 rounded-lg">
        <table class="w-full">
          <thead>
            <tr>
              <th
                class="py-2"
                v-for="pickupConfig in Object.keys(orderInfos.pickupConfig)"
                :key="pickupConfig">
                {{pickupConfig.toLowerCase()}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                class="pb-2 text-xl font-semibold text-center cursor-pointer "
                v-for="pickupConfig in Object.keys(orderInfos.pickupConfig)"
                :key="pickupConfig">
                <div>
                  <input class="w-16 pl-4 rounded-full" type="number" v-model="orderInfos.pickupConfig[pickupConfig]">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex items-center justify-between mt-5">
          <Button class="mr-5" type="secondary" @click.native="updatePickupConfig">Modifier</Button>
          <Button type="tertiary" @click.native="pickupFormDisplayed = false">Annuler</Button>
        </div>
      </div>
    </div>
    <template v-for="pickupTime in orderInfos.pickupTimes">
      <div
        v-if="orderInfos.orders[pickupTime.toUpperCase()]"
        :key="pickupTime"
        :id="pickupTime"
        class="p-5 my-4 bg-gray-200 rounded-lg">
        <h2 class="pb-5 text-lg font-semibold">
          Commandes pour {{pickupTime.toLowerCase()}}
        </h2>
        <div class="space-y-4">
          <OrderDetail
            v-for="order in orderedOrders(orderInfos.orders[pickupTime.toUpperCase()])"
            :key="order.id" :order="order"
            @sendSms="sendSms(order)"
            @sendInternalEmail="sendInternalEmail(order)" />
        </div>
      </div>
    </template>
  </div>

</template>

<script>
import api from '@store/api'

import orderBy from 'lodash.orderby'

import Button from '@ui/Button'
import PickupTimeStatus from '@components/admin/order/PickupTimeStatus'
import OrderDetail from '@components/admin/order/OrderDetail'

export default {
  components: {
    Button,
    OrderDetail,
    PickupTimeStatus
  },
  data() {
    return {
      orderInfos: null,
      pickupFormDisplayed: false
    }
  },
  computed: {
    orderedOrders() {
      return order => orderBy(order, ['number'], ['asc'])
    },
    sortedOrdersByPickupTime() {
      return Object.keys(this.orderInfos.orders).sort((k, _) => k)
    },
    formattedPickupConfig() {
      let key
      const keys = Object.keys(this.orderInfos.pickupConfig)
      const newobj = {}
      for (let i = 0; i < keys.length; i++) {
        key = keys[i]
        newobj[key.toLowerCase()] = this.orderInfos.pickupConfig[key] ? parseInt(this.orderInfos.pickupConfig[key]) : 0
      }

      return newobj
    }
  },
  methods: {
    async sendSms(order) {
      const { data } = await api.put(`/admin/order/${order.id}/send_sms`)
      order.smsSentAt = data.smsSentAt

      if (order.smsSentAt) {
        window.alert('✅ SMS envoyé !')
      }
      else {
        window.alert('Aie, le SMS n’est toujours pas passé… réessayez plus tard.')
      }
    },
    async sendInternalEmail(order) {
      await api.put(`/admin/order/${order.id}/send_internal_email`)
      window.alert('✅ E-mail envoyé ! 🤞')
    },
    async updatePickupConfig() {
      try {
        const { data } = await api.patch(`/admin/service/${this.$route.params.id}/pickup_config`, {
          pickup_config: this.formattedPickupConfig
        })

        this.orderInfos = data
        this.pickupFormDisplayed = false
      }
      catch {
        window.alert('Aie, les creneaux n’ont pas été mis à jour. Contacter pat !')
      }
    }
  },
  async mounted() {
    const { data } = await api.get(`/admin/service/${this.$route.params.id}/orders`)
    this.orderInfos = data
  }
}
</script>
