import Vue from 'vue'
import 'intl'
import 'intl/locale-data/jsonp/fr.js'

const formatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2
})

Vue.filter('formattedCents', cents => formatter.format(cents / 100))
