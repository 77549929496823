<template>
  <div class="p-5 bg-gray-200 rounded-lg">
    <table class="w-full">
      <thead>
        <tr>
          <th
            class="py-2"
            v-for="pickupTime in pickupTimes"
            :key="pickupTime">
            {{pickupTime}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            class="pb-2 text-xl font-semibold text-center cursor-pointer "
            v-for="pickupTime in pickupTimes"
            :key="pickupTime"
            @click="goToHash(pickupTime)">
            <div
              :style="{backgroundColor: colorCode(orderCount(pickupTime), maxOrderCount(pickupTime))}"
              class="flex items-center justify-center w-16 h-10 mx-auto rounded-full">
              <div>{{ orderCount(pickupTime) }}&nbsp;
                <span v-if="maxOrderCount(pickupTime)" class="text-sm">/ {{ maxOrderCount(pickupTime) }}</span>
                <span v-else>🔒</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    pickupTimes: {
      type: Array,
      required: true
    },
    pickupConfig: {
      type: Object,
      required: true
    },
    orders: {
      type: Object,
      required: true
    }
  },
  computed: {
    orderCount() {
      return pickupTime => this.orders[pickupTime.toUpperCase()] ? this.orders[pickupTime.toUpperCase()].length : 0
    },
    maxOrderCount() {
      return pickupTime => this.pickupConfig[pickupTime.toUpperCase()]
    }
  },
  methods: {
    colorCode(number, total) {
      if (number === 0 || total === 0) return '#E0E0E0'

      var hue = ((1 - (number * 100 / total / 100)) * 120).toString(10)
      return ['hsl(', hue, ',62%,50%)'].join('')
    },
    goToHash(pickupTime) {
      location.href = `#${pickupTime}`
    }
  }
}
</script>
